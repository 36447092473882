<template>
  <div
    v-if="isAuthenticated && !isFormFillingPeriod"
    class="form-filling-period-message"
  >
    <b-card>
      <b-alert
        variant="danger"
        show
      >
        <h2 class="alert-heading">
          Important
        </h2>
        <div class="alert-body">
          <p>
            Les fiches sont accessibles du 1 <sup>er</sup> au 10 du mois

          </p>
          <p>
            Vous ne serez pas en mesure de remplir des fiches statistiques en dehors de cette période
          </p>

        </div></b-alert></b-card></div>
  </b-alert>
  </b-card>

  </div>
</template>

<script>
import { canFillForm as isFormFillingPeriod } from '@/helpers/manage-form-filling-period'
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody,
  BCardText, BRow, BCol, BButton, BCarousel, BCarouselSlide, BImg, BAlert,
} from 'bootstrap-vue'
import { mapGetters } from "vuex";

export default {
  components: {
    BAlert, BCard,
  },
  setup() {

  },
  computed: {
       ...mapGetters("auth", {
      isAuthenticated: "getter_isAuthenticated",
      isAutoLoggedOut: "getter_isAutoLoggedOut",
      callToRegister: "getter_callToRegister",
    }),
    isFormFillingPeriod() {
      return isFormFillingPeriod
    },
  },
//   name: 'ColorBandGouvBj',
}
</script>
<style lang="scss" scoped>

</style>
